export const APP_NAME = 'Horys Mall';

export const COOKIES_NAME = {
    authToken: 'horys-mall-token',
    userSessionId: 'horys-mall-session',
    requestUrl: 'requestUrl',
};

export const DEFAULT_CURRENCY = '$';

export const PLACEHOLDER_IMAGE = '/img/img-placeholder-illustration.svg';

export const PROFILE_PLACEHOLDER_IMAGE = '/img/profile-avatar.svg';

export const CART_STATUS = {
    checkout: 'checkout',
    payment: 'payment',
    shopping: 'shopping',
};
export const QUANTITY_ACTIONS: { add: string; minus: string } = {
    add: 'add',
    minus: 'minus',
};

export const METHOD_TO_PAY = {
    card: 'card',
    wallet: 'wallet',
};

export const ORDER_STATUS = {
    PENDING: 'pending',
    PROCESSING: 'processing',
    COMPLETED: 'completed',
    FAILED: 'failed',
};

export const paymentStatusTheme = {
    [ORDER_STATUS.COMPLETED]: {
        color: 'green',
        className: 'text-green-500 bg-green-100',
    },
    [ORDER_STATUS.PROCESSING]: {
        color: 'orange',
        className: 'text-orange-500 bg-orange-100',
    },
    [ORDER_STATUS.PENDING]: {
        color: 'orange',
        className: 'text-orange-500 bg-orange-100',
    },
    [ORDER_STATUS.FAILED]: {
        color: 'red',
        className: 'text-red-500 bg-red-100',
    },
};

export const APP_LANG = ['en', 'it'];
