'use client';

import { Typography } from '@material-tailwind/react';
import CustomNextImage from '@/shared/components/CustomNextImage';
import CustomNextLink from '@/shared/components/CustomNextLink';
import notFoundImage from '../../public/img/pagenotfound.png';
export default function NotFound() {
  return <>
            <div className="flex flex-col justify-center items-center h-screen">
                <div className="flex flex-col items-center justify-center p-5">
                    <CustomNextImage src={notFoundImage.src} alt="Page Not Found" width={650} height={212} data-sentry-element="CustomNextImage" data-sentry-source-file="not-found.tsx" />
                    <Typography variant="h3" className="mb-5 text-2xl text-black-500" data-sentry-element="Typography" data-sentry-source-file="not-found.tsx">
                        Page Not Found
                    </Typography>
                    <Typography variant="h5" className="text-base text-gray-500 font-normal mb-4 text-center" data-sentry-element="Typography" data-sentry-source-file="not-found.tsx">
                        Oops! The page you&apos;re looking for doesn&apos;t seem
                        to exist.
                    </Typography>
                    <CustomNextLink href="/" className="text-sm bg-purple-500 text-white py-2 px-4 rounded-md" data-sentry-element="CustomNextLink" data-sentry-source-file="not-found.tsx">
                        Home page
                    </CustomNextLink>
                </div>
            </div>
        </>;
}